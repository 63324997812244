import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className="breadcrumb-area2"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="text-center relative px-2 md:px-44 space-y-16">
        <p className="text-white font-semibold text-5xl">{headertitle}</p>

        
      </div>
    </div>
  );
};

export default TransparentHeader;
