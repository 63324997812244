import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { GiTeamIdea } from 'react-icons/gi';
import { BsClock, BsPerson, BsTools } from 'react-icons/bs';




const BuildingMaintenance1 = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className=' py-[50px]'>
            <div className='w-full flex flex-col items-center mx-auto'>
                {rpdata?.dbServices?.slice(2, 3).map((serv, index) => {
                    return (
                        <>
                            <div className='w-4/5 flex flex-col pb-14 px-10 text-center items-center justify-center'>
                                <h3 className='text-[25px] md:text-[35px] pb-3 capitalize text-primary font-semibold'>{rpdata?.dbSlogan?.[15]?.slogan}</h3>
                                <div className='w-[80px] border-2 border-rose-500'></div>
                            </div>
                            <div className='w-4/5 flex flex-col md:flex-row py-16'>
                                <div className='w-full md:w-1/2 '>
                                    <img
                                        src={serv.description[0].img}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-full object-cover'
                                    />
                                </div>
                                <div className='w-full md:w-1/2 px-10 flex flex-col justify-center'>
                                    <p className='pb-3'>{serv?.description[0].text}</p>
                                </div>

                            </div>
                            <div className='w-4/5 flex flex-col px-10 text-center items-center justify-center'>
                                <p className='py-10 text-black'>{serv?.description[5].text}</p>
                                <h3 className='text-[25px] md:text-[30px] pb-3 capitalize text-primary font-semibold'>{rpdata?.dbSlogan?.[12]?.slogan}</h3>
                            </div>
                            <section className="w-full flex flex-col justify-center items-center pb-10">
                                <div className="w-4/5 text-start text-secondary flex flex-col md:flex-row justify-center items-start space-x-4 pt-20">
                                    <div className="w-full md:w-[25%] py-10 md:py-0 px-5 text-justify">
                                        <GiTeamIdea size={40}/>
                                        <h4 className="text-3xl font-semibold pt-4">Hands-on transition</h4>
                                        <p className="leading-[1.8em] pt-4">{serv?.description[1].text}</p>
                                    </div>
                                    <div className="w-full md:w-[25%] py-10 md:py-0 px-5 text-justify">
                                        <BsClock size={40}/>
                                        <h4 className="text-3xl font-semibold pt-4">24/7 Support</h4>
                                        <p className="leading-[1.8em] pt-4">{serv?.description[2].text}</p>
                                    </div>
                                    <div className="w-full md:w-[25%] py-10 md:py-0 px-5 text-justify">
                                        <BsPerson size={40}/>
                                        <h4 className="text-3xl font-semibold pt-4">Qualified staff</h4>
                                        <p className="leading-[1.8em] pt-4">{serv?.description[3].text}</p>
                                    </div>
                                    <div className="w-full md:w-[25%] py-10 md:py-0 px-5 text-justify">
                                        <BsTools size={40}/>
                                        <h4 className="text-3xl font-semibold pt-4">Equipment analysis</h4>
                                        <p className="leading-[1.8em] pt-4">{serv?.description[4].text}</p>
                                    </div>
                                </div>
                            </section>



                        </>
                    )
                })}

            </div>
        </div>
    )
}


export default BuildingMaintenance1;