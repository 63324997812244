import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom';

const PrimaryButton = ({ BgColorWhite, url, text, direction, urlPhone, btnUrlTel, btnphone }) => {
    const { rpdata } = useContext(GlobalDataContext);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <div>
            {rpdata?.simpleWidgets?.[3]?.activo ? null : (
                <>
                    {urlPhone ? (
                        <div
                            className={`lg:w-1/2 w-full flex flex-col items-center md:items-start text-center lg:text-start`}
                        >
                            <a
                                className={`w-[220px] h-auto  px-12 py-4 ${direction ? "rounded-l-full" : "rounded-r-full"}  ${BgColorWhite
                                    ? "bg-secondary text-primary transition ease-in-out delay-200 hover:translate-x-5 duration-300"
                                    : direction ?
                                        "bg-secondary transition ease-in-out delay-200 hover:-translate-x-5 duration-300" : 'bg-secondary transition ease-in-out delay-200 hover:translate-x-5 duration-300'
                                    } `}
                                    href={btnUrlTel ? btnUrlTel : `tel:+1${btnphone}`}
                                onClick={goToTop}

                            >
                                <span className={`font-bold  ${BgColorWhite ? "text-primary" : "text-white"}`}>
                                    {text ? text : "Free Estimate"}
                                </span>
                            </a>
                        </div>
                    ) : (
                        <div
                            className={`lg:w-1/2 w-full flex flex-col items-center md:items-start text-center lg:text-start`}
                        >
                            <Link
                                className={`w-[200px] h-auto flex items-center justify-center space-x-8 py-4 ${direction ? "rounded-sm" : "rounded-sm"}  ${BgColorWhite
                                    ? "bg-btn text-primary transition ease-in-out delay-200 hover:translate-x-5 duration-300"
                                    : direction ?
                                        "bg-secondary group" : 'bg-btn group'
                                    } `}
                                to={url ? url : "/contact"}
                                onClick={goToTop}

                            >
                                <div className='w-[100px] h-1 border-b-2 border-white transition ease-in-out delay-200 group-hover:-translate-x-12 duration-300 -ml-36'></div>
                                <span className={`font-bold  ${BgColorWhite ? "text-primary" : "text-white"}`}>
                                    {text ? text : "Contact Us"}
                                </span>
                            </Link>
                        </div>)}
                </>
            )}
        </div>
    )
}
export default PrimaryButton;
