import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { MdOutlineLocationOn } from 'react-icons/md';
import { FiSmartphone } from 'react-icons/fi';
import { BiEnvelope } from 'react-icons/bi';
import { BsClockHistory } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Navmenu from './NavMenu'




const Header_9
    = () => {
        const { rpdata } = useContext(GlobalDataContext);

        return (
            <div>
                <div className="bg1 py-1.5">
                    <div className="md:w-[90%] mx-auto md:flex justify-between items-center">
                        <ul className="flex justify-center space-x-4 pb-4 md:pb-0">
                            <li className="text-white md:text-[16px] text-[13px] flex justify-center items-center border-r-[1px] border-white pr-3">
                                <MdOutlineLocationOn size={20} />
                                <span>

                                    {rpdata?.dbPrincipal?.location[0]?.address}
                                </span>
                            </li>
                            
                            {
                                rpdata?.dbPrincipal?.phones?.length > 1 &&
                                <li className="text-white md:text-[16px] space-x-1 text-[13px] flex justify-center items-center border-r-[1px] border-white pr-3">
                                    <FiSmartphone size={20} />
                                    <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </a>
                                </li>
                            }
                            <li className="text-white md:text-[16px] space-x-1 text-[13px] hidden md:flex justify-center items-center border-r-[1px] border-white pr-3">
                                <BiEnvelope size={20} />
                                <a href={`mailto:${rpdata?.dbPrincipal?.emails?.[0]?.email}`}>
                                    {rpdata?.dbPrincipal?.emails?.[0]?.email}
                                </a>
                            </li>
                            <li className="text-white md:text-[16px] space-x-1 text-[13px] hidden md:flex justify-center items-center border-r-[1px] border-white pr-3">
                                <BsClockHistory size={20} />
                                <span>
                                    {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                </span>
                            </li>

                        </ul>
                        <ul className="flex justify-end space-x-4 w-[240px] px-4">
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="text-white">
                                        <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                            <i
                                                className={`fab fa-${item.icon}`}
                                                aria-hidden="true"
                                            />
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                        <Link to={"/contact"}>
                            <div className='w-[240px] h-auto py-[1.5px] bg-transparent hidden md:block hover:bg-white text-white hover:text-blue-700 border-2 border-white text-center'>
                                <p>Submit a Service Request</p>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="bg2">
                    <div className="w-4/5 md:w-4/5 mx-auto flex justify-between items-center">
                        <Link to={"/"} className='w-full mr-0 md:w-[35%] lg:w-[20%]'>
                            <img
                                src={rpdata?.dbPrincipal?.logo}
                                alt='logo'
                                loading='lazy'
                                className='w-[80%] mx-auto -mt-4'
                            />
                        </Link >
                        <div className='block w-[20%] lg:w-[75%] self-center'>
                            <Navmenu />
                        </div>
                    </div>
                </div>

            </div>
        )
    }

export default Header_9
