import React from 'react';
import {  BiMessageRounded } from 'react-icons/bi';
import { GiTeamIdea } from 'react-icons/gi';
import { MdOutlineLocationOn } from 'react-icons/md';





const ItemConst = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {


    return (
        
            <div className='w-full mx-auto flex justify-center bg-slate-200 py-20'>


                    <div className='w-4/5 flex flex-col md:flex-row justify-center space-x-0 md:space-x-5 '>
                        <div className='w-full bg-white md:w-1/3  flex '>
                            <div className='w-[40%] p-5 h-full bg-primary text-white flex items-center justify-center'>
                                <BiMessageRounded size={50} />
                            </div>
                            <div className='w-full px-5  flex flex-col justify-center items-start'>
                                <h3 className='text-base pb-3 capitalize text-primary'>Contact our Experts</h3>
                                <h3 className='text-2xl font-semibold capitalize'>Communication</h3>
                            </div>

                        </div>
                        <div className='w-full bg-white md:w-1/3  flex '>
                            <div className='w-[40%] p-5 h-full bg-primary text-white flex items-center justify-center'>
                                <GiTeamIdea size={50} />
                            </div>
                            <div className='w-full px-5  flex flex-col justify-center items-start'>
                                <h3 className='text-base pb-3 capitalize text-primary'>Work With Us</h3>
                                <h3 className='text-2xl font-semibold capitalize'>Support Team</h3>
                            </div>

                        </div>
                        <div className='w-full bg-white md:w-1/3  flex '>
                            <div className='w-[40%] p-5 h-full bg-primary text-white flex items-center justify-center'>
                                <MdOutlineLocationOn size={50} />
                            </div>
                            <div className='w-full px-5  flex flex-col justify-center items-start'>
                                <h3 className='text-base pb-3 capitalize text-primary'>Locations Near You</h3>
                                <h3 className='text-2xl font-semibold capitalize'>Serving the Sanger, Texas</h3>
                            </div>

                        </div>
                    </div>





            </div>

    )
}


export default ItemConst;