import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import FormHome from "../components/Home/FormHome";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import BlockPrincipal from "../components/block/Block_swiper";
import TransparentHeader from "../components/global/TransparentHeader";
import BlockOne from "../components/block/Block_1";
import Paletacolor from "../components/global/Paletacolor";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}
// import CustomReviews from "../components/reviews/CustomReviews";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <input className="absolute top-0 -z-50" autoFocus></input>
        {/* <Modal /> */}
        <TransparentHeader
          Subheader={rpdata?.dbSlogan?.[2]?.slogan}
          headertitle={rpdata?.dbSlogan?.[3]?.slogan}
          bgimg={`${rpdata?.gallery?.[17]}`}
        />
        <BlockPrincipal
          title={rpdata?.dbSlogan?.[0]?.slogan}
          title2={rpdata?.dbSlogan?.[1]?.slogan}
          image={rpdata?.gallery?.[4]}
          image1={rpdata?.gallery?.[5]}
          image2={rpdata?.gallery?.[6]}
          image3={rpdata?.gallery?.[20]}

        />
        <CounterUp image={rpdata?.stock?.[9]} />

        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[3]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <BlockOne text={rpdata?.dbHome?.[0].text}/>

        <Paletacolor/>
  
      </div>

    </BaseLayout>
  );
}

export default Home;
