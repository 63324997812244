import React, { useContext } from 'react';
import { VscDebugBreakpointData } from 'react-icons/vsc';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';



const BlockOne = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className=' py-[150px]'>
            <div className='w-[95%] md:w-4/5 mx-auto'>
                <div className='flex flex-col md:flex-row px-5 md:px-10'>
                    <div className='w-full flex justify-end md:w-1/2 ml-0 -mt-20 mb-0 md:ml-2 md:-my-20'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6991603.699017098!2d-100.0768425!3d31.168910300000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864070360b823249%3A0x16eb1c8f1808de3c!2sTexas%2C%20USA!5e0!3m2!1sen!2sni!4v1710185384429!5m2!1sen!2sni"
                            loading="lazy"
                            title="Cover Locations"
                            className="w-[450px] h-[450px]"
                        />
                    </div>
                    <div className='w-full md:w-1/2 flex bg-white mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-20 text-center md:text-start'>
                        <div className='flex flex-col justify-center '>
                            <p className='pb-3'>
                                {
                                    text ? text :
                                        <span>{`para agregar el texto -> text={'description'}`}
                                            <br />{'para agregar lista de about -> listsAbout={true}'}
                                            <br />{'para agregar lista de servicios -> listsServices={true}'}
                                        </span>
                                }
                            </p>
                            {
                                listsAbout ?
                                    <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                        {
                                            rpdata?.dbAbout?.[0].list.length > 1 ?
                                                rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center">
                                                            <VscDebugBreakpointData />
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                listsServices ?
                                    <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                        {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <VscDebugBreakpointData />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }

                            <div>
                                <ButtonContent btnName={"Learn More"} btnLink="about" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default BlockOne;