import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../../context/context";

function Footer4() {
  const { rpdata } = useContext(GlobalDataContext);
  const yearsActual = new Date();

  const menu = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Gallery",
      link: "/gallery",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];


  // agregar la pestaña de reviews al array de dbMenu

  const el = {
    name: `Reviews`,
    link: `/reviews`,
    child: false,
  };
  rpdata?.simpleWidgets?.forEach((element) => {
    const num = menu.length - 1;
    if (element.val === "ReviewTab" && element.activo === true) {
      menu.splice(num, 0, el);
    }
  });
  //  fin de agregar pestaña de reviews



  return (
    <>
      <div className="w-full flex flex-col justify-center bg-gray-400 bg-opacity-5 px-8 py-2 bg-footer text-white">
        <div className="md:w-[90%] mx-auto text-center flex justify-center mb-4 md:flex-row flex-col-reverse  rounded-xl">
          <div className="md:w-[20%] flex justify-center self-center">
            <img
              src={rpdata?.dbPrincipal?.logo}
              className="md:w-[400px] w-[280px] object-cover"
              alt="Frank Fence & Construction"
              loading="lazy"
            />
          </div>
          <div className="w-full md:w-[80%] flex flex-col md:flex-row items-center justify-end">
            <ul className="flex flex-col md:flex-row items-center justify-center">
            <li className="text-base px-2 border-r border-white">
                <span className="capitalize">
                {rpdata?.dbPrincipal?.licensed}
                </span>
              </li>
              {rpdata?.dbPrincipal?.location
                ?.slice(0, 1)
                .map((item, index) => {
                  return (
                    <li key={index}>
                      <Link to="/contact" className="py-2 flex items-center">

                        <span className="text-base px-2 border-r border-white">{item.address}</span>
                      </Link>
                    </li>
                  );
                })}
              {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      href={`tel:+1${item.phone}`}
                      className="py-2 flex items-center"
                    >

                      <span className="text-base px-2 border-r border-white">{item.phone} {item.name}</span>
                    </a>
                  </li>
                );
              })}

              <a href={`mailto:${rpdata?.dbPrincipal?.emails[0].email}`}>
                <li className="py-2 flex items-center">
                  <span className="text-base px-2">
                    {rpdata?.dbPrincipal?.emails[0].email}
                  </span>
                </li>
              </a>
              


            </ul>
          </div>

        </div>


      </div>
      <div className="w-full text-start px-5 md:px-20 text-white  py-4 bg2">
        <p className="text-lg">
          ©{yearsActual.getFullYear()}, Copyright By {rpdata?.dbPrincipal?.name}. All Rights Reserved
        </p>
      </div>
    </>
  );
}

export default Footer4;
