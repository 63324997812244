import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ItemAbout from "../components/About/ItemsAbout";
import BlockAbout3 from "../components/About/BlockAbout3";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          Subheader={rpdata?.dbSlogan?.[10]?.slogan}
          headertitle="About Alanis Plumbing Contractors LLC"
          bgimg={`${rpdata?.gallery?.[6]}`}
        />
        <ItemAbout/>
        <BlockAbout3/>
      </div>
    </BaseLayout>
  );
}

export default About;
