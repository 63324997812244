import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';




const BlockOneConst = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className=' pt-[150px]'>
            <div className='w-full flex flex-col items-center mx-auto'>
                {rpdata?.dbServices?.slice(0, 1).map((serv, index) => {
                    return (
                        <>
                            <div className='w-4/5 flex flex-col-reverse md:flex-row'>
                                <div className='w-full md:w-1/2 px-10 flex flex-col justify-center'>
                                    <h3 className='text-[25px] md:text-[30px] pb-3 capitalize text-primary'>{rpdata?.dbSlogan?.[5]?.slogan}</h3>
                                    <p className='pb-3'>{serv?.description[0].text}</p>
                                </div>
                                <div className='w-full md:w-1/2 '>
                                    <img
                                        src={serv.description[0].img}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-[300px] md:h-full object-cover'
                                    />
                                </div>
                            </div>
                            <div className='w-4/5 flex flex-col md:flex-row py-16'>
                                <div className='w-full md:w-1/2 '>
                                    <img
                                        src={serv.description[1].img}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-[300px] md:h-full object-cover'
                                    />
                                </div>
                                <div className='w-full md:w-1/2 px-10 flex flex-col justify-center'>
                                    <h3 className='text-[25px] md:text-[30px] pb-3 capitalize text-primary'>Expertise</h3>
                                    <p className='pb-3'>{serv?.description[1].text}</p>
                                </div>

                            </div>
                            <div className='w-4/5 flex flex-col-reverse md:flex-row pb-16'>

                                <div className='w-full md:w-1/2 px-10 flex flex-col justify-center'>
                                    <h3 className='text-[25px] md:text-[30px] pb-3 capitalize text-primary'>{rpdata?.dbSlogan?.[14]?.slogan}</h3>
                                    <p className='pb-3'>{serv?.description[2].text}</p>
                                </div>
                                <div className='w-full md:w-1/2 '>
                                    <img
                                        src={serv.description[2].img}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-[300px] md:h-full object-cover'
                                    />
                                </div>

                            </div>
                            <div className='w-full flex flex-col py-16 px-10 text-center items-center justify-center' style={{ backgroundImage: `url("${serv.description[3].img}")` }}>
                                <h3 className='text-[25px] md:text-[35px] pb-3 capitalize text-white font-semibold'>{rpdata?.dbSlogan?.[6]?.slogan}</h3>
                                <div className='w-[80px] border-2 border-rose-500'></div>
                                <p className='py-10 text-white'>{serv?.description[3].text}</p>
                            </div>

                        </>
                    )
                })}

            </div>
        </div>
    )
}


export default BlockOneConst;