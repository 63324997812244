import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader3";
import BaseLayout from "../components/global/BaseLayout";
import Remodel1 from "../components/Remodel/Remodel1";







function Remodel() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Remodel">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Remodel"
          Subheader="Remodel"
          bgimg={`${rpdata?.gallery?.[13]}`}
        />
        <Remodel1/>
        
      </div>
    </BaseLayout>
  );
}

export default Remodel;
