import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader3";
import BaseLayout from "../components/global/BaseLayout";
import BuildConstruction1 from "../components/BuildConstruction/BuildConstruction1";






function BuildConstruction() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Build Construction">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Build Construction"
          Subheader="Build Construction"
          bgimg={`${rpdata?.gallery?.[13]}`}
        />
        <BuildConstruction1/>
        
      </div>
    </BaseLayout>
  );
}

export default BuildConstruction;
