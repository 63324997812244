import React from 'react';





const ItemAbout = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {


    return (

        <div className='w-full mx-auto flex flex-col items-center justify-center bg-slate-200 py-20'>

            <div className='w-4/5 flex flex-col pb-14 px-10 text-center items-center justify-center'>
                <h3 className='text-[25px] md:text-[35px] pb-3 capitalize text-primary font-semibold'>Our Core Values</h3>
                <div className='w-[80px] border-2 border-rose-500'></div>
            </div>
            <div className='w-4/5 flex flex-col md:flex-row justify-center space-x-0 md:space-x-3 '>
                <div className='w-full md:w-auto  flex '>
                    <div className='w-auto h-full  text-white flex flex-col items-center justify-center'>
                        <img className='w-[205px] h-[160px]' src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Alanis%20Plumbing%20Contractors%20LLC%2FValueIcons_Final-01.png?alt=media&token=2cc86a83-d75f-408c-b46d-5a873d2577e7' alt='values' />
                        <h3 className='text-lg text-primary font-semibold capitalize'>Problem Solver</h3>
                    </div>
                    <div className='w-auto h-full  text-white flex flex-col items-center justify-center'>
                        <img className='w-[205px] h-[160px]' src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Alanis%20Plumbing%20Contractors%20LLC%2FValueIcons_Final-02.png?alt=media&token=adec062d-c285-47ab-bf5a-cad6f98d4671' alt='values' />
                        <h3 className='text-lg text-primary font-semibold capitalize'>Positive Attitude</h3>
                    </div>
                    <div className='w-auto h-full  text-white flex flex-col items-center justify-center'>
                        <img className='w-[205px] h-[160px]' src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Alanis%20Plumbing%20Contractors%20LLC%2FValueIcons_Final-03.png?alt=media&token=c0c0b4ec-863b-410c-a59b-3b39745994f8' alt='values' />
                        <h3 className='text-lg text-primary font-semibold capitalize'>Always Growing</h3>
                    </div>
                    <div className='w-auto h-full  text-white flex flex-col items-center justify-center'>
                        <img className='w-[205px] h-[160px]' src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Alanis%20Plumbing%20Contractors%20LLC%2FValueIcons_Final-04.png?alt=media&token=05dc7fe7-2e5f-4579-a46c-32aafd1ead84' alt='values' />
                        <h3 className='text-lg text-primary font-semibold capitalize'>Own It</h3>
                    </div>
                    <div className='w-auto h-full  text-white flex flex-col items-center justify-center'>
                        <img className='w-[205px] h-[160px]' src='https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Alanis%20Plumbing%20Contractors%20LLC%2FValueIcons_Final-05.png?alt=media&token=89e70b80-d682-49a5-b025-7d84446df617' alt='values' />
                        <h3 className='text-lg text-primary font-semibold capitalize'>Team Driven</h3>
                    </div>


                </div>

            </div>





        </div>

    )
}


export default ItemAbout;