import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import PrimaryButton from '../global/boton/PrimaryButton';




const BlockAbout3 = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='' >
            <div className='w-full flex flex-col items-end mx-auto' style={{ backgroundImage: `url("${rpdata?.gallery?.[16]}")` }}>
                <div className='w-full md:w-1/2 h-auto py-10 bg-[#0e298ca2] px-6'>
                    <h3 className='text-[25px] md:text-[35px] pb-3 capitalize text-white font-semibold'>{rpdata?.dbSlogan?.[2]?.slogan}</h3>
                    <p className='pb-10 text-white'>{rpdata?.dbAbout?.[1]?.text}</p>
                    <PrimaryButton/>
                </div>
            </div>
            {rpdata?.dbServices?.slice(0, 1).map((serv, index) => {
                return (
                    <>
                        <div className='w-full'></div>
                        <div className='w-full flex flex-col py-16 px-10 text-center items-center justify-center' style={{ backgroundImage: `url("${serv.description[3].img}")` }}>
                            <h3 className='text-[25px] md:text-[35px] pb-3 capitalize text-white font-semibold'>{rpdata?.dbSlogan?.[9]?.slogan}</h3>
                            <div className='w-[80px] border-2 border-rose-500'></div>
                            <p className='py-10 text-white'>{rpdata?.dbAbout?.[2]?.text}</p>
                        </div>

                    </>
                )
            })}
        </div>
    )
}


export default BlockAbout3;