import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const Paletacolor = () => {

    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='w-full mx-auto mt-0 md:mt-10 px-10'>
            <h2 className='capitalize text-center text-2xl text-black'>
            our partners:
            </h2>
            <div className='flex flex-wrap justify-center items-center space-x-4'>
                {
                    rpdata?.brandingExtra?.map((item, index) => {
                        return (
                            <a href={item.link} target="_blank" rel="noreferrer" key={index}>
                                <img src={item.img} className='w-[180px] h-[100px] object-contain' alt='no found' />
                            </a>
                        )
                    })
                }


            </div>
        </div>

    )
}


export default Paletacolor;