import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context';



function FormHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className='w-full flex justify-center'>
        <div className='w-4/5 p-5 mx-5 '>
          <div className='p-4 w-full md:w-1/2 border text-white'>
            <span className='text-[#9fa9d1]'>Get In Touch</span>
            <h4 className='text-start text-white'>Send Us A Message</h4>
            <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}>
              Submit the form below or call us at {rpdata?.dbPrincipal?.phones?.[0]?.phone}
            </a>
            <div className='my-2'>
              <div class="elfsight-app-220aabb9-f55f-4de6-9059-3b53cde10d5a" data-elfsight-app-lazy></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormHome
