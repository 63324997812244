import React from "react";
import PrimaryButton from "./boton/PrimaryButton";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <div
      className="breadcrumb-area"
      style={{ backgroundImage: `url("${bgimg}")` }}
    >
      <div className="text-start relative px-2 md:px-44 space-y-16">
      {
          headertitle === Subheader ?
            <h6 className="text-white font-light">-{Subheader}</h6>
            :
            <h6 className="text-white font-normal text-lg">{Subheader}</h6>
        }
        <p className="text-white font-semibold text-5xl">{headertitle}</p>
        <PrimaryButton/>
        
      </div>
    </div>
  );
};

export default TransparentHeader;
