import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader3";
import BaseLayout from "../components/global/BaseLayout";
import DesingBuild1 from "../components/DesingBuild/DesingBuild1";




function DesingBuild() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="DesingBuild">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Desing Build"
          Subheader="Desing Build"
          bgimg={`${rpdata?.gallery?.[10]}`}
        />
        <DesingBuild1/>
        
      </div>
    </BaseLayout>
  );
}

export default DesingBuild;
