import React, { useContext } from 'react'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { GlobalDataContext } from '../../context/context'
import { MdOutlineHeadsetMic } from 'react-icons/md'
import { BsEnvelopeOpen } from 'react-icons/bs'


const ContactInfo2 = ({ image1, image }) => {

    const { rpdata } = useContext(GlobalDataContext)
    return (
        <>

            <div className='w-[80%] mx-auto grid grid-cols-1 md:grid-cols-2 mt-10'>
                <div className='relative'>

                    <div className='mt-10 ml-[30px] md:ml-[50px] px-5 md:px-[40px] pt-[80px] bg-white'>
                        <h4 className='text-primary font-semibold'>Our Contact Info</h4>
                        <div className='grid grid-cols-1'>
                            <div className='flex mb-5'>
                                <div className='self-center pr-4'>
                                    <FaMapMarkedAlt fontSize={35} color={'#313131'} />
                                </div>
                                <div className='self-center px-3'>
                                    <p className='text-[20px] font-semibold'>Address</p>
                                    <p className='bgIconContact1 font-medium'>
                                        {
                                            rpdata?.dbPrincipal?.location.map((item, index) => {
                                                return (

                                                    <p className='text-[20px] font-normal'>{item.address}</p>

                                                )
                                            })
                                        }
                                    </p>
                                </div>
                            </div>
                            {
                                rpdata?.dbPrincipal?.phones?.length > 1 &&
                                <div className='flex mb-5'>
                                    <div className='self-center pr-4'>
                                        <MdOutlineHeadsetMic fontSize={35} color={'#313131'} />
                                    </div>
                                    <div className='self-center px-3'>
                                        <p className='text-[20px] font-semibold'>Phone</p>
                                        <p className='bgIconContact1 font-medium'>
                                            {
                                                rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                    return (
                                                        <a
                                                            key={index}
                                                            href={`tel:+1${phone.phone}`}
                                                        >
                                                            <p className='text-[20px] font-normal'>{phone.phone}</p>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </p>
                                    </div>
                                </div>
                            }

                            <div className='flex mb-5'>
                                <div className='self-center pr-4'>
                                    <BsEnvelopeOpen fontSize={35} color={'#313131'} />
                                </div>
                                <div className='self-center px-3'>
                                    <p className='text-[20px] font-semibold'>Emails</p>
                                    <p className='bgIconContact1 font-medium'>
                                        {
                                            rpdata?.dbPrincipal?.emails.map((item, index) => {
                                                return (
                                                    <a
                                                        key={index}
                                                        href={`mailto:${item.email}`}
                                                    >
                                                        <p className='text-[20px] font-normal'>{item.email}</p>
                                                    </a>
                                                )
                                            })
                                        }
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col ">
                            {
                                rpdata?.dbSocialMedia?.redes?.length > 0 ?
                                    <div>
                                        <h4 className='py-5'>Follow Us</h4>
                                        <ul className="flex space-x-7">
                                            {
                                                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                                    return (
                                                        <li key={index} className="bgIconContact transition-all ease-in-out duration-300">
                                                            <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                                                <i
                                                                    className={`fab fa-${item.icon} text-[20px]`}
                                                                    aria-hidden="true"
                                                                />
                                                            </a>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className='mb-10'>
                    <div className='relative'>
                        <iframe
                            src={rpdata?.dbPrincipal?.location?.[0].url}
                            loading="lazy"
                            title="Cover Locations"
                            className="w-full h-[500px]"
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactInfo2