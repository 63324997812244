import React, { useContext } from "react";
import imgDefault from '../../assets/image/placeholder.png'
import { GlobalDataContext } from "../../context/context";



const Block_10 = ({ title, title2, listsAbout, image3, image, image2, image1 }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <section className="w-full flex flex-col justify-center items-center py-10">
                <div className="w-4/5 text-center space-y-4 flex flex-col items-center">
                    <p className="text-base">{title}</p>
                    <h4 className="text-4xl font-bold text-secondary pb-3">{title2}</h4>
                    <div className="w-[6%] border-2 border-btn"></div>
                </div>
                <div className="w-[90%] text-start text-secondary flex flex-col md:flex-row justify-center items-start space-x-4 pt-20">
                    <div className="w-full md:w-1/3 py-10 md:py-0 px-5">
                        <img src={image ? image : imgDefault} alt="img" className="w-full h-[250px]" />
                        <h4 className="text-3xl font-semibold pt-4">Restaurants</h4>
                        <p className="leading-[1.8em] pt-4">{rpdata?.dbValues?.[0].description}</p>
                    </div>
                    <div className="w-full md:w-1/3 py-10 md:py-0 px-5">
                        <img src={image1 ? image1 : imgDefault} alt="img" className="w-full h-[250px]"/>
                        <h4 className="text-3xl font-semibold pt-4">Healthcare Facilities</h4>
                        <p className="leading-[1.8em] pt-4">{rpdata?.dbValues?.[1].description}</p>
                    </div>
                    <div className="w-full md:w-1/3 py-10 md:py-0 px-5">
                        <img src={image2 ? image2 : imgDefault} alt="img" className="w-full h-[250px]"/>
                        <h4 className="text-3xl font-semibold pt-4">Commercial Real Estate</h4>
                        <p className="leading-[1.8em] pt-4">{rpdata?.dbValues?.[2].description}</p>
                    </div>
                    <div className="w-full md:w-1/3 py-10 md:py-0 px-5">
                        <img src={image3 ? image3 : imgDefault} alt="img" className="w-full h-[250px]"/>
                        <h4 className="text-3xl font-semibold pt-4"> MIXED-USED BUILDINGS</h4>
                        <p className="leading-[1.8em] pt-4">{rpdata?.dbHome?.[3].text}</p>
                    </div>
                </div>
            </section>
        </>
    );

}

export default Block_10;