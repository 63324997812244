import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader3";
import BaseLayout from "../components/global/BaseLayout";
import BuildingMaintenance1 from "../components/BuildingMaintenance/BuildingMaintenance1";





function BuildingMaintenance() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Building Maintenance">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Building Maintenance"
          Subheader="Building Maintenance"
          bgimg={`${rpdata?.gallery?.[12]}`}
        />
        <BuildingMaintenance1/>
        
      </div>
    </BaseLayout>
  );
}

export default BuildingMaintenance;
