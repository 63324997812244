import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';




const BuildConstruction1 = ({ text, listsAbout, listsServices, image, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className=' py-[50px]'>
            <div className='w-full flex flex-col items-center mx-auto'>
                {rpdata?.dbServices?.slice(3, 4).map((serv, index) => {
                    return (
                        <>
                            <div className='w-4/5 flex flex-col pb-14 px-10 text-center items-center justify-center'>
                                <h3 className='text-[25px] md:text-[35px] pb-3 capitalize text-primary font-semibold'>{rpdata?.dbSlogan?.[2]?.slogan}</h3>
                                <div className='w-[80px] border-2 border-rose-500'></div>
                            </div>
                            <div className='w-4/5 flex flex-col md:flex-row py-16'>
                                <div className='w-full md:w-1/2 '>
                                    <img
                                        src={serv.description[0].img}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-full object-cover'
                                    />
                                </div>
                                <div className='w-full md:w-1/2 px-10 flex flex-col justify-center'>
                                    <p className='pb-3'>{serv?.description[0].text}</p>
                                </div>

                            </div>
                            <div className='w-4/5 flex flex-col-reverse md:flex-row py-16'>
                                
                                <div className='w-full md:w-1/2 px-10 flex flex-col justify-center'>
                                    <p className='pb-3'>{serv?.description[1].text}</p>
                                </div>
                                <div className='w-full md:w-1/2 '>
                                    <img
                                        src={serv.description[1].img}
                                        alt='no found'
                                        loading='lazy'
                                        className='w-full h-[300px] md:h-full object-cover'
                                    />
                                </div>

                            </div>
                            



                        </>
                    )
                })}

            </div>
        </div>
    )
}


export default BuildConstruction1;