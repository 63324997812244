import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
import CountUp from 'react-countup';



const CounterUp = ({ image }) => {

    const { rpdata } = useContext(GlobalDataContext);


    const counterInfo = [
        {
            title: rpdata?.dbPrincipal?.exprYears ? 'Years Of Experience' : 'Best Service',
            number: rpdata?.dbPrincipal?.exprYears ? rpdata?.dbPrincipal?.exprYears : '100',
            simbolo: rpdata?.dbPrincipal?.exprYears ? '>' : '>',
        },
        {
            title: 'Permits Withdrawn',
            number: 100,
            simbolo: '>',
        },
        {
            title: rpdata?.dbPrincipal?.miles ? `Miles in Texas` : 'Cost-Effectiveness',
            number: rpdata?.dbPrincipal?.miles ? rpdata?.dbPrincipal?.miles : '100',
            simbolo: rpdata?.dbPrincipal?.miles ? '>' : '>',
        },
        {
            title: 'Annual Projects',
            number: 100,
            simbolo: '>',
        },
    ]

    return (
        <div className='w-full py-24 flex flex-col justify-center items-center bg2'>
            <p className='text-center py-4 relative text-white tracking-tight text-3xl font-semibold'>{rpdata?.dbSlogan?.[4].slogan}</p>
            <div className="w-[6%] border-2 border-btn"></div>
            <div className='w-4/5 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pt-10 gap-4'>
                {
                    counterInfo.map((items, index) => {
                        
                        return (
                            <div key={index} className="text-center p-3 relative bg-[#9ba0af3f] py-20">
                                <div>
                                <span className='text-white text-5xl font-semibold'>{items.simbolo}</span>
                                    <CountUp
                                        end={items.number}
                                        duration={2}
                                        className='text-white text-5xl font-semibold'
                                    />
                                    
                                </div>
                                <p className='text-[#6a90e4]'>{items.title}</p>
                            </div>
                        )
                    })
                }
                <div>

                </div>
            </div>
        </div>
    )
}
export default CounterUp
